<div>
    <canvas id="stacked-chart"></canvas>
</div>

<script>
    import {Bar} from "vue-chartjs";
    import Chart from "chart.js";
    // import Chart from "chart.js";
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: 'chart_1_4',
        extends: Bar,
        data () {
            return {
                chartOptions4: {
                    "animation": {
                        "onProgress": function() {
                            let chartInstance = this.chart,
                                ctx = chartInstance.ctx;

                            ctx.font = Chart.helpers.fontString(14, 'Bold', 'Prompt');
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fillStyle = 'black'
                            this.data.datasets.forEach(function(dataset, i) {
                                let meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function(bar, index) {
                                    let data = dataset.data[index];
                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                });
                            });
                        }
                    },
                    title: {
                        display: false,
                    },
                    tooltips: {
                        enabled: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            beforeFit: function (scale) {
                                let maxValue = 0
                                if (scale.chart.config && scale.chart.config.data && scale.chart.config.data.datasets) {
                                    scale.chart.config.data.datasets.forEach(dataset => {
                                        if (dataset && dataset.data) {
                                            dataset.data.forEach(value => {
                                                if (value > maxValue) {
                                                    maxValue = value
                                                }
                                            })
                                        }
                                    })
                                }
                                scale.max = maxValue + (maxValue * 0.1)
                            }
                        }],
                        xAxes: [ {
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }
                        }]
                    },
                    legend: {
                        display: false,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                chartData4: {
                    labels: ['สิ่งกระตุ้น','สัมพันธภาพในครอบครัว','ปัญหาเศรษฐกิจ','ปัญหาสุขภาพกาย','ปัญหาสุขภาพจิต','อื่นๆ'],
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                },
            }
        },
        component: {
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_1_4',this.year).then(res=> {
                this.chartData4.datasets = [
                    {
                        backgroundColor: "rgba(255, 191, 135, 1)",
                        data: res.data.data,
                    },
                ];
                this.renderChart(this.chartData4, this.chartOptions4);
            });
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_1_4',newVal).then(res=> {
                    this.chartData4.datasets = [
                        {
                            backgroundColor: "rgba(255, 191, 135, 1)",
                            data: res.data.data,
                        },
                    ];
                    this.renderChart(this.chartData4, this.chartOptions4);
                });
            }
        },
        methods:{
        }
    }
</script>
