<div>
    <canvas id="stacked-chart"></canvas>
</div>

<script>
    import {Bar} from "vue-chartjs";
    // import Chart from "chart.js";
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: 'chart_2_3',
        extends: Bar,
        component: {
        },
        data () {
            return {
                chartOptions3 : {
                    title: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                padding: 20
                            },
                            stacked: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'จำนวน',
                                font: {
                                    family : 'Prompt'
                                }
                            },
                        }],
                        xAxes: [ {
                            stacked: true,
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                        }],
                    },
                    legend: {
                        display: false,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                chartData3: {
                    labels: ['ให้คำปรึกษาอายุครรภ์ต่ำกว่า 12 สัปดาห์','ให้คำปรึกษาอายุครรภ์ 12 - 20 สัปดาห์','ให้คำปรึกษาอายุครรภ์ 20 สัปดาห์ขึ้นไป'],
                },
            }
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_2_3',this.year).then(res=> {
                this.chartData3.datasets = [
                    {
                        data : res.data.data,
                        backgroundColor: "rgba(166, 224, 207, 1)",
                    },
                ];
                this.renderChart( this.chartData3, this.chartOptions3);
            })
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_2_3',newVal).then(res=>{
                    this.chartData3.datasets = [
                        {
                            data : res.data.data,
                            backgroundColor: "rgba(166, 224, 207, 1)",
                        },
                    ];
                    this.renderChart( this.chartData3, this.chartOptions3);
                })
            }
        },
        methods:{
        }
    }
</script>
