<template>
    <div>
        <div>
            <b-row>
                <b-col md="9"></b-col>
                <b-col md="3">
                    <span style="font-size: 22px">ปี พ.ศ.</span>
                    <div>
                        <b-form-select style="border-radius: 20px;font-size: 18px" v-model="selected_year"
                                        v-on:input="changeYear"
                                       :options="options  || []" size="sm" class="mt-1">

                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="mt-3 content-graph-oscc">
            <b-row>
                <b-col md="3"></b-col>
                <b-col md="6" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้ที่ถูกกระทำความรุนแรงที่มารับบริการในศูนย์พึ่งได้</span><br/>
                    <span style="color:#6368EC">จำนวนโรงพยาบาล {{ hospital_count }} แห่ง</span>
                    <chart_1_1 class="mt-5" :year="year_1_1"></chart_1_1>
                </b-col>
                <b-col md="3"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนมาผู้รับบริการ จำแนกตามเพศและช่วงอายุของผู้ที่ถูกกระทำรุนแรง</span>
                    <span style="position: absolute;left: 5%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 15%;right: -3%;font-size: 12px;font-weight: bold">ช่วงอายุ</span>
                    <chart_1_2 :year="year_1_2"></chart_1_2>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้มารับบริการจำแนกตามช่วงอายุของผู้ที่ถูกกระทำความรุนแรงที่มาขอรับบริการ</span>
                    <span style="position: absolute;left: 4%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 15%;right: -8%;font-size: 12px;font-weight: bold">ประเภทความรุนแรง</span>
                    <chart_1_3 :year="year_1_3"></chart_1_3>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้มารับบริการจำแนกตามปัจจัยกระตุ้นให้เกิดการกระทำความรุนแรง</span>
                    <span style="position: absolute;left: 4%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 8%;right: -5%;font-size: 12px;font-weight: bold">ปัจจัยกระตุ้น</span>
                    <chart_1_4 :year="year_1_4"></chart_1_4>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <Map1 :src_map="src_map" :map="map" :under="underline"></Map1>
            <!--      <b-row>-->
            <!--        <b-col md="12" style="height: 700px">-->
            <!--          <div class="text-center">-->
            <!--            <span class="head-graph-oscc">แผนที่สัดส่วนจำนวนผู้ที่ถูกกระทำรุนแรงเทียบกับจำนวนประชากร</span>-->
            <!--          </div>-->
            <!--          <b-row class="mt-5 pr-5">-->
            <!--            <b-col md="4" style="height: 520px">-->
            <!--              <iframe-->
            <!--                      :src="src_map"-->
            <!--                      width="100%"-->
            <!--                      height="100%"-->
            <!--                      frameborder="0" >-->
            <!--              </iframe>-->
            <!--              <span class="dot" style="background-color: #66BB3C"></span><span class="dot-text">เท่ากับ 0</span><br/>-->
            <!--              <span class="dot" style="background-color: #FFFF65"></span><span class="dot-text">มากกว่า 0 ถึง 110</span><br/>-->
            <!--              <span class="dot" style="background-color: #FFCC00"></span><span class="dot-text">มากกว่า 110 ถึง 235</span><br/>-->
            <!--              <span class="dot" style="background-color: #FD0002"></span><span class="dot-text">มากกว่า 235</span>-->
            <!--            </b-col>-->
            <!--            <b-col md="8" class="table-responsive" style="height: 650px;">-->
            <!--              <div>-->
            <!--                <table class="table table-striped">-->
            <!--                  <thead>-->
            <!--                    <tr class="text-center">-->
            <!--                      <th scope="col">ลำดับ</th>-->
            <!--                      <th scope="col">จังหวัด</th>-->
            <!--                      <th scope="col">จำนวนผู้<br/>มารับบริการ</th>-->
            <!--                      <th scope="col">จำนวนประชากร<br/>ในจังหวัด</th>-->
            <!--                      <th scope="col">สัดส่วนต่อประชากร<br/>100,000 คน</th>-->
            <!--                    </tr>-->
            <!--                  </thead>-->
            <!--                  <tbody>-->
            <!--                  <tr v-for="(val,i,index) in map" v-bind:key="i" :tabindex="i">-->
            <!--                    <td class="text-center">-->
            <!--                      {{ index+1 }}-->
            <!--                    </td>-->
            <!--                    <td>{{ val.name }}</td>-->
            <!--                    <td class="text-center">{{ val.val }}</td>-->
            <!--                    <td class="text-center">test3</td>-->
            <!--                    <td class="text-center">test4</td>-->
            <!--                  </tr>-->
            <!--                  </tbody>-->
            <!--                </table>-->
            <!--              </div>-->
            <!--            </b-col>-->
            <!--          </b-row>-->
            <!--        </b-col>-->
            <!--      </b-row>-->
        </div>
    </div>
</template>

<script>
import chart_1_1 from "./charts/chart_1_1";
import chart_1_2 from "./charts/chart_1_2";
import chart_1_3 from "./charts/chart_1_3";
import chart_1_4 from "./charts/chart_1_4";
import Map1 from "./Map1";
import {domain} from "../util/Constants";
// import Chart from "chart.js";

export default {
    components: {
        Map1,
        chart_1_4,
        chart_1_3,
        chart_1_2,
        chart_1_1
    },
    computed: {},
    created() {
        this.fillDataMap1();
        this.showLoading();
        this.$store.dispatch('dashboard/get_year_violence').then(res => {
            this.options = res.data.data
            this.$forceUpdate();
            // this.hideLoading()
        })
    },
    data() {
        return {
            hospital_count: 0,
            src_map: '',
            map: [],
            index: 1,
            options: [],
            selected_year: new Date().getFullYear() + 543,
            year_1_1: new Date().getFullYear() + 543,
            year_1_2: new Date().getFullYear() + 543,
            year_1_3: new Date().getFullYear() + 543,
            year_1_4: new Date().getFullYear() + 543,
            underline: true
        };
    }
    , mounted() {

    },
    methods: {
        changeYear() {
            this.fillDataMap1();
        },
        fillDataMap1() {
            // this.selected_year = this.options[this.options.length-1].value
            this.year_1_1 = this.selected_year;
            this.year_1_2 = this.selected_year;
            this.year_1_3 = this.selected_year;
            this.year_1_4 = this.selected_year;
            this.$store.dispatch('dashboard/map_1', {
                'year': this.selected_year,
                'area': '',
                'province': ''
            }).then(res => {
                this.map = res.data.data;
                this.hospital_count = res.data.total_hosp;
                this.src_map = domain.slice(0, -4) + '/map_thai1?year=' + this.selected_year + '&area=&province=';
                this.$forceUpdate();
                //this.src_map = 'http://localhost:8000/map_thai1?year='+this.selected_year+'&area=&province=';
                this.hideLoading()
            })
        },
        showLoading() {
            this.$loading.show({
                background:
                    this.$swal.fire({
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })
                , animation: true
            });
        },
        hideLoading() {
            this.$loading.hide();
            this.$swal.hideLoading()
        },
    },
};
</script>

<style>
.content-graph-oscc {
    padding-top: 2%;
    padding-bottom: 2%;
    background: #FFFFFF;
    border: 0.5px solid #A8A8A8;
    box-sizing: border-box;
    box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
    border-radius: 5px;
}

.content-graph-oscc span.head-graph-oscc {
    font-weight: bold;
    font-size: 20px;
}
</style>
