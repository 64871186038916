<template>
    <div class="mt-5 row">
        <div class="col-12 text-center">
            <h2 v-if="currentPage === 1" style="color: rgba(91, 69, 223, 1);font-size: 52px;">Dashboard ข้อมูลผู้ที่ถูกกระทำรุนแรง</h2>
            <h2 v-if="currentPage === 2" style="color: rgba(8, 146, 146, 1);font-size: 52px;">Dashboard ข้อมูลผู้ที่ตั้งครรภ์ไม่พึงประสงค์</h2>
        </div>
        <div class="col-12 text-center mt-4">
<!--            <div class="mt-4 text-center">-->
                <b-button-group class="bg-grey-theme" style="width: 60%">
                    <b-button
                            @click="clickPage(item)"
                            v-for="item in buttonList"
                            :key="item.id"
                            :class="item.isActive ? 'btn-page-violence px-5' : 'btn-no-page-violence px-5'">
                        {{ item.name }}
                    </b-button>
                </b-button-group>
<!--            </div>-->
        </div>
        <div class="container my-5" style="max-width: 1200px">
            <Dashboard1 v-if="currentPage === 1"></Dashboard1>
            <Dashboard2 v-if="currentPage === 2"></Dashboard2>
        </div>
    </div>
</template>

<script>
    import Dashboard1 from "../components/Dashboard1";
    import Dashboard2 from "../components/Dashboard2";
    export default {
        name: "Graph",
        components: {Dashboard2, Dashboard1},
        comments:{
        },
        data() {
            return {
                buttonList: [
                    { id: 1, name: "Dashboard ข้อมูลผู้ที่ถูกกระทำรุนแรง", isActive: true },
                    { id: 2, name: "Dashboard ข้อมูลผู้ที่ตั้งครรภ์ไม่พึงประสงค์", isActive: false },
                ],
                currentPage: 1,
            }
        },
        created() {
            const page = this.$route.query.id ?  this.$route.query.id: 1;
            this.currentPage = parseInt(page);

            this.buttonList.forEach(x=>{
                if(x.id === this.currentPage) x.isActive =true;
                else x.isActive = false
            })
        },
        methods: {
            clickPage(model) {
                this.$router.push({
                    path: 'dashboard',
                    query: { id: model.id },
                });
            },
        },
    }
</script>

<style scoped>

</style>
