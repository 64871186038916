<div>
    <canvas id="stacked-chart"></canvas>
</div>

<script>
    import {Bar} from "vue-chartjs";
    // import Chart from "chart.js";
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: 'chart_2_2',
        extends: Bar,
        component: {
        },
        data () {
            return {
                chartOptions2 : {
                    title: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                padding: 20
                            },
                            stacked: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'จำนวน',
                                font: {
                                    family : 'Prompt'
                                }
                            },
                        }],
                        xAxes: [ {
                            stacked: true,
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                        }],
                    },
                    legend: {
                        display: false,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                chartData2: {
                    // labels: [['ปัญหา','จากการคุมกำเนิด'],'ปัญหาการตั้งครรภ์ที่เกิดจากการกระทำผิดทางกฏหมาย','ปัญหาทางด้านเศรษฐกิจ/สังคม','ปัญหาสัมพันธภาพภายในครอบครัว','ปัญหาทางด้านการแพทย์ ตามมาตรา305(1)(2)'],
                    labels: [['ปัญหา','จากการคุมกำเนิด'],['ปัญหาการตั้งครรภ์','ที่เกิดจากการกระทำผิดทางกฏหมาย'],['ปัญหาทางด้าน','เศรษฐกิจ/สังคม'],['ปัญหาสัมพันธภาพ','ภายในครอบครัว'],['ปัญหาทางด้านการแพทย์','ตามมาตรา305(1)(2)']],
                },
            }
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_2_2',this.year).then(res=> {
                this.chartData2.datasets = [
                    {
                        data: res.data.data,
                        backgroundColor: "rgba(162, 141, 246, 1)",
                    },
                ];
                this.renderChart(this.chartData2, this.chartOptions2);
            })
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_2_2',newVal).then(res=>{
                    this.chartData2.datasets = [
                        {
                            data: res.data.data,
                            backgroundColor: "rgba(162, 141, 246, 1)",
                        },
                    ];
                    this.renderChart(this.chartData2, this.chartOptions2);
                })
            }
        },
        methods:{
        }
    }
</script>
