<div>
    <canvas id="stacked-chart"></canvas>
</div>

<script>
    import {Bar} from "vue-chartjs";
    // import Chart from "chart.js";
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: 'chart_1_3',
        extends: Bar,
        data() {
            return {
                chartOptions3: {
                    title: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                        }],
                        xAxes: [ {
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        displayColors: false,
                        callbacks: {
                            title: function() {
                                return '';
                            },
                            label: function(tooltipItem, data) {
                                let total = data['datasets'][tooltipItem.datasetIndex]['custom_variable'][tooltipItem.index].reduce((partial_sum, a) => partial_sum + a, 0);
                                let sex_m = data['datasets'][tooltipItem.datasetIndex]['custom_variable'][tooltipItem.index][1]/total*100;
                                let sex_w = data['datasets'][tooltipItem.datasetIndex]['custom_variable'][tooltipItem.index][0]/total*100;
                                let sex_o = data['datasets'][tooltipItem.datasetIndex]['custom_variable'][tooltipItem.index][2]/total*100;

                                let str = ['อัตราส่วนเพศ : '+ data['datasets'][tooltipItem.datasetIndex].label];
                                //ชาย
                                str.push('ชาย               ร้อยละ : ' + sex_m.toFixed(2) + ' %')
                                //หญิง
                                str.push('หญิง              ร้อยละ : ' + sex_w.toFixed(2) + ' %')
                                //เพศทางเลือก
                                str.push('เพศทางเลือก   ร้อยละ : ' + sex_o.toFixed(2) + ' %')
                                return str;
                            },
                        },
                    }
                },
                chartData3: {
                    labels: ['ไม่เกิน 10 ปี', '10 ปี - ไม่เกิน 20 ปี', '20 ปี - ไม่เกิน 30 ปี', '30 ปี - ไม่เกิน 40 ปี', '40 ปี - ไม่เกิน 50 ปี', '50 ปี - ไม่เกิน 60 ปี', '60 ปีขึ้นไป'],
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                },
            }
        },
        component: {
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_1_3',this.year).then(res=> {
                this.chartData3.datasets = [
                    {
                        type: 'bar',
                        label: 'ทางร่างกาย',
                        backgroundColor: 'rgba(159, 191, 255, 1)',
                        data: res.data.data[0],
                        custom_variable : res.data.custom_variable[0]
                    },
                    {
                        type: 'bar',
                        label: 'ทางเพศ',
                        backgroundColor: 'rgba(255, 150, 150, 1)',
                        data: res.data.data[1],
                        custom_variable : res.data.custom_variable[1]
                    },
                    {
                        type: 'bar',
                        label: 'ทางจิตใจ',
                        backgroundColor: 'rgba(169, 147, 255, 1)',
                        data: res.data.data[2],
                        custom_variable : res.data.custom_variable[2]
                    },
                    {
                        type: 'bar',
                        label: 'ละเลยทอดทิ้ง',
                        backgroundColor: 'rgba(151, 233, 233, 1)',
                        data: res.data.data[3],
                        custom_variable : res.data.custom_variable[3]
                    },
                    {
                        type: 'bar',
                        label: 'ล่อลวง/แสวงหาผลประโยชน์',
                        backgroundColor: 'rgba(255, 221, 156, 1)',
                        data: res.data.data[4],
                        custom_variable : res.data.custom_variable[4]
                    }
                ];
                this.renderChart( this.chartData3,this.chartOptions3 );
            });
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_1_3',newVal).then(res=> {
                    this.chartData3.datasets = [
                        {
                            type: 'bar',
                            label: 'ทางร่างกาย',
                            backgroundColor: 'rgba(159, 191, 255, 1)',
                            data: res.data.data[0],
                            custom_variable : res.data.custom_variable[0]
                        },
                        {
                            type: 'bar',
                            label: 'ทางเพศ',
                            backgroundColor: 'rgba(255, 150, 150, 1)',
                            data: res.data.data[1],
                            custom_variable : res.data.custom_variable[1]
                        },
                        {
                            type: 'bar',
                            label: 'ทางจิตใจ',
                            backgroundColor: 'rgba(169, 147, 255, 1)',
                            data: res.data.data[2],
                            custom_variable : res.data.custom_variable[2]
                        },
                        {
                            type: 'bar',
                            label: 'ละเลยทอดทิ้ง',
                            backgroundColor: 'rgba(151, 233, 233, 1)',
                            data: res.data.data[3],
                            custom_variable : res.data.custom_variable[3]
                        },
                        {
                            type: 'bar',
                            label: 'ล่อลวง/แสวงหาผลประโยชน์',
                            backgroundColor: 'rgba(255, 221, 156, 1)',
                            data: res.data.data[4],
                            custom_variable : res.data.custom_variable[4]
                        }
                    ];
                    this.renderChart( this.chartData3,this.chartOptions3 );
                });
            }
        },
        methods:{
        }
    }
</script>
