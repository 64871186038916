<template>
    <div>
        <div>
            <b-row>
                <b-col md="9"></b-col>
                <b-col md="3">
                    <span style="font-size: 22px">ปี พ.ศ.</span>
                    <div>
                        <b-form-select style="border-radius: 20px;font-size: 18px" v-model="selected_year"
                                        v-on:input="changeYear"
                                       :options="options  || []" size="sm" class="mt-1">

                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนมาผู้รับบริการ จำแนกตามช่วงอายุของผู้ที่ตั้งครรภ์ไม่พึ่งประสงค์</span>
                    <span style="position: absolute;left: 5%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 15%;right: -3%;font-size: 12px;font-weight: bold">ช่วงอายุ</span>
                    <chart_2_1 :year="year_2_1"></chart_2_1>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้มารับบริการจำแนกตามสาเหตุการตั้งครรภ์ไม่พึงประสงค์</span>
                    <span style="position: absolute;left: 5%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 8%;right: -2%;font-size: 12px;font-weight: bold">สาเหตุ</span>
                    <chart_2_2 :year="year_2_2"></chart_2_2>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้มารับบริการจำแนกตามการให้การปรึกษาทางเลือก</span>
                    <span style="position: absolute;left: 5%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 8%;right: -7%;font-size: 12px;font-weight: bold">การให้การปรึกษา</span>
                    <chart_2_3 :year="year_2_3"></chart_2_3>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <b-row>
                <b-col md="1"></b-col>
                <b-col md="10" class="text-center">
                    <span class="head-graph-oscc">จำนวนผู้มารับบริการจำแนกตามผลการตัดสินใจของผู้รับบริการหลังให้การปรึกษาทางเลือก</span>
                    <span style="position: absolute;left: 5%;font-weight: bold">จำนวน</span>
                    <span style="position: absolute;bottom: 8%;right: -7%;font-size: 12px;font-weight: bold">ผลการตัดสินใจ</span>
                    <chart_2_4 :year="year_2_4"></chart_2_4>
                </b-col>
                <b-col md="1"></b-col>
            </b-row>
        </div>
        <div class="mt-5 content-graph-oscc">
            <Map2 :src_map="src_map" :map="map" :under="underline"></Map2>
        </div>
    </div>
</template>

<script>
import chart_2_1 from "./charts/chart_2_1";
import chart_2_2 from "./charts/chart_2_2";
import chart_2_3 from "./charts/chart_2_3";
import chart_2_4 from "./charts/chart_2_4";
import Map2 from "./Map2";
import {domain} from "../util/Constants";

export default {
    components: {
        Map2,
        chart_2_1,
        chart_2_2,
        chart_2_3,
        chart_2_4,
    },
    computed: {},
    created() {
        this.showLoading()
        this.$store.dispatch('dashboard/get_year_pregnant').then(res => {
            let checkYear = res.data.data.filter(x => (x.value == this.selected_year))
            if (checkYear.length == 0)
                this.selected_year = res.data.data[res.data.data.length - 1].value
            this.options = res.data.data
            this.$forceUpdate();
        })
        this.fillDataMap2();
    },
    data() {
        return {
            src_map: '',
            map: [],
            options: [],
            selected_year: new Date().getFullYear() + 543,
            year_2_1: new Date().getFullYear() + 543,
            year_2_2: new Date().getFullYear() + 543,
            year_2_3: new Date().getFullYear() + 543,
            year_2_4: new Date().getFullYear() + 543,
            underline: true
        };
    },
    methods: {
        changeYear() {
            this.fillDataMap2();
        },
        fillDataMap2() {
            this.year_2_1 = this.selected_year;
            this.year_2_2 = this.selected_year;
            this.year_2_3 = this.selected_year;
            this.year_2_4 = this.selected_year;
            this.$store.dispatch('dashboard/map_2', {
                'year': this.selected_year,
                'area': '',
                'province': ''
            }).then(res => {
                this.map = res.data.data;
                this.src_map = domain.slice(0, -4) + '/map_thai2?year=' + this.selected_year + '&area=&province=';
                // this.src_map = 'http://localhost:8000/map_thai2?year='+this.selected_year+'&area=&province=';
                this.hideLoading()
            })
        },
        showLoading() {
            this.$loading.show({
                background:
                    this.$swal.fire({
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })
                , animation: true
            });
        },
        hideLoading() {
            this.$loading.hide();
            this.$swal.hideLoading()
        },
    },
};
</script>

<style>
.content-graph-oscc {
    padding-top: 2%;
    padding-bottom: 2%;
    background: #FFFFFF;
    border: 0.5px solid #A8A8A8;
    box-sizing: border-box;
    box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
    border-radius: 5px;
}

.content-graph-oscc span.head-graph-oscc {
    font-weight: bold;
    font-size: 20px;
}
</style>
