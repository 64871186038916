<template>
    <b-row>
        <b-col md="12" style="height: 730px">
            <div class="text-center">
                <span class="head-graph-oscc">แผนที่สัดส่วนจำนวนผู้ตั้งครรภ์ไม่พึ่งประสงค์เทียบกับจำนวนประชากร</span>
            </div>
            <b-row class="mt-5 pr-5">
                <b-col md="4" style="height: 550px">
                    <iframe
                            :src="src_map"
                            width="100%"
                            height="100%"
                            frameborder="0" >
                    </iframe>
                    <span class="dot" style="background-color: #66BB3C"></span><span class="dot-text">เท่ากับ 0</span><br/>
                    <span class="dot" style="background-color: #FFFF65"></span><span class="dot-text">มากกว่า 0 ถึง 133</span><br/>
                    <span class="dot" style="background-color: #FFCC00"></span><span class="dot-text">มากกว่า 133 ถึง 266</span><br/>
                    <span class="dot" style="background-color: #FD0002"></span><span class="dot-text">มากกว่า 266</span>
                </b-col>
                <b-col md="8" class="table-responsive" style="height: 650px;" v-if="!province">
                    <div>
                        <table class="table table-striped">
                            <thead>
                            <tr class="text-center">
                                <th scope="col">ลำดับ</th>
                                <th scope="col">จังหวัด</th>
                                <th scope="col">จำนวนผู้<br/>มารับบริการ</th>
                                <th scope="col">จำนวนประชากร<br/>ในจังหวัด</th>
                                <th scope="col">สัดส่วนต่อประชากร<br/>100,000 คน</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val,i,index) in map" v-bind:key="i" :tabindex="i">
                                <td class="text-center">
                                    {{ index+1 }}
                                </td>
                                <td v-if="under">{{val.name}}</td>
                                <td v-else><a style="text-decoration: underline" @click="getProvince(val.name)">{{val.name}}</a></td>
                                <td class="text-center">{{ Intl.NumberFormat().format(val.val) }}</td>
                                <td class="text-center">{{ Intl.NumberFormat().format(val.people) }}</td>
                                <td class="text-center">{{ Intl.NumberFormat('th-TH', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    }).format((val.val/parseInt(val.people))*100000) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
                <b-col md="8" class="table-responsive" style="height: 650px;" v-if="province">
                    <div>
                        <table class="table table-striped">
                            <thead>
                            <tr class="text-center">
                                <th scope="col">ลำดับ</th>
                                <th scope="col">อำเภอ</th>
                                <th scope="col">จำนวนผู้<br/>มารับบริการ</th>
                                <th scope="col">จำนวนประชากร<br/>ในจังหวัด</th>
                                <th scope="col">สัดส่วนต่อประชากร<br/>100,000 คน</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(val,i) in map" v-bind:key="i" :tabindex="i">
                                <td class="text-center">
                                    {{ i+1 }}
                                </td>
                                <td>{{val.name}}</td>
                                <td class="text-center">{{ Intl.NumberFormat().format(val.val) }}</td>
                                <td class="text-center">{{ Intl.NumberFormat().format(val.people) }}</td>
                                <td class="text-center">{{ Intl.NumberFormat('th-TH', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    }).format((val.val/parseInt(val.people))*100000) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "Map2",
        props:["map","src_map","province","under"],
        methods:{
            getProvince(name){
                this.$emit("submit", name);
            }
        }
    }
</script>

<style scoped>
    .dot {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5%;
    }
    .dot-text {
        position: relative;
        top: -5px;
        margin-left: 7%;
    }
</style>
