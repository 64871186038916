<div>
    <canvas id="stacked-chart"></canvas>
</div>

<script>
    import {Bar} from "vue-chartjs";
    import Chart from "chart.js";
    // import Chart from "chart.js";
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: 'chart_1_2',
        extends: Bar,
        data(){
            return {
                chartOptions2 : {
                    "animation": {
                        "onProgress": function() {
                            let chartInstance = this.chart,
                                ctx = chartInstance.ctx;

                            ctx.font = Chart.helpers.fontString(14, 'Bold', 'Prompt');
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fillStyle = 'black'
                            this.data.datasets.forEach(function(dataset, i) {
                                let meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function(bar, index) {
                                    let data = dataset.data[index];
                                    if (data>=25)ctx.fillText(data, bar._model.x, bar._model.y + 15);
                                });
                            });
                        }
                    },
                    title: {
                        display: false,
                    },
                    tooltips: {
                        enabled: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                padding: 20,
                            },
                            stacked: true,
                        }],
                        xAxes: [ {
                            stacked: true,
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                        }],
                    },
                    legend: {
                        display: true,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                chartData2: {
                    labels: ['ไม่เกิน 10 ปี', '10 ปี - ไม่เกิน 20 ปี', '20 ปี - ไม่เกิน 30 ปี', '30 ปี - ไม่เกิน 40 ปี', '40 ปี - ไม่เกิน 50 ปี', '50 ปี - ไม่เกิน 60 ปี', '60 ปีขึ้นไป'],
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                },
            }
        },
        component: {
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_1_2',this.year).then(res=>{
                this.chartData2.datasets = [
                    {
                        type: 'bar',
                        label: 'หญิง',
                        backgroundColor: 'rgba(132, 169, 242, 1)',
                        data: res.data.data[0]
                    },
                    {
                        type: 'bar',
                        label: 'ชาย',
                        backgroundColor: 'rgba(99, 104, 236, 1)',
                        data: res.data.data[1]
                    },
                    {
                        type: 'bar',
                        label: 'เพศทางเลือก',
                        backgroundColor: 'rgba(215, 239, 232, 1)',
                        data: res.data.data[2]
                    }
                ];
                this.renderChart(this.chartData2,this.chartOptions2);
            })
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_1_2',newVal).then(res=>{
                    this.chartData2.datasets = [
                        {
                            type: 'bar',
                            label: 'หญิง',
                            backgroundColor: 'rgba(132, 169, 242, 1)',
                            data: res.data.data[0]
                        },
                        {
                            type: 'bar',
                            label: 'ชาย',
                            backgroundColor: 'rgba(99, 104, 236, 1)',
                            data: res.data.data[1]
                        },
                        {
                            type: 'bar',
                            label: 'เพศทางเลือก',
                            backgroundColor: 'rgba(215, 239, 232, 1)',
                            data: res.data.data[2]
                        }
                    ];
                    this.renderChart(this.chartData2,this.chartOptions2);
                })
            }
        },
        methods:{

        }
    }
</script>
