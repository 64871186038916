<div>
    <canvas id="doughnut-chart"></canvas>
</div>

<script>
    import {Doughnut} from "vue-chartjs";
    import Chart from "chart.js";

    export default {
        name: 'chart_1_1',
        extends: Doughnut,
        data() {
            return {
                chartOptions1: {
                    animateRotate: true,
                    cutoutPercentage: 70,
                    hoverBorderWidth: 20,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                                fontFamily: "Prompt",
                            }
                        }],
                    },
                    responsive : true,
                    showAllTooltips: true,
                    tooltips: {
                        enabled: false,
                        callbacks: {
                            title: function(tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function(tooltipItem, data) {
                                return data['datasets'][0]['data'][tooltipItem['index']] + '(ราย)';
                            },
                        },
                    }
                },
                chartData1: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: [ "เพศหญิง", "เพศชาย","เพศทางเลือก"],
                },
            }
        },
        component: {
        },
        props: ["year"],
        mounted() {
            this.$store.dispatch('dashboard/chart_1_1',this.year).then(res=>{
                this.chartData1.datasets = [
                    {
                        backgroundColor: ["#84A9F2", "#B0E7D7","#C4C4C4"],
                        data: res.data.data
                    }
                ];
                this.textCenter(res.data.textCenter);
                this.renderChart(this.chartData1, this.chartOptions1);
            })
        },
        watch: {
            year: function(newVal) {
                this.$store.dispatch('dashboard/chart_1_1',newVal).then(res=>{
                    this.chartData1.datasets = [
                        {
                            backgroundColor: ["#84A9F2", "#B0E7D7","#C4C4C4"],
                            data: res.data.data
                        }
                    ];
                    this.textCenter(res.data.textCenter);
                    this.renderChart(this.chartData1, this.chartOptions1);
                })
            }
        },
        methods:{
            textCenter(val) {
                Chart.pluginService.register({
                    beforeRender: function(chart) {
                        if (chart.config.options.showAllTooltips) {
                            chart.pluginTooltips = [];
                            chart.config.data.datasets.forEach(function(dataset, i) {
                                chart.getDatasetMeta(i).data.forEach(function(sector) {
                                    chart.pluginTooltips.push(new Chart.Tooltip({
                                        _chart: chart.chart,
                                        _chartInstance: chart,
                                        _data: chart.data,
                                        _options: chart.options.tooltips,
                                        _active: [sector]
                                    }, chart));
                                });
                            });
                            chart.options.tooltips.enabled = false;
                        }
                    },
                    afterDraw: function(chart, easing) {
                        if (chart.config.options.showAllTooltips) {
                            if (!chart.allTooltipsOnce) {
                                if (easing !== 1)
                                    return;
                                chart.allTooltipsOnce = true;
                            }
                            chart.options.tooltips.enabled = true;
                            Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
                                tooltip.initialize();
                                tooltip.update();
                                tooltip.pivot();
                                tooltip.transition(easing).draw();
                            });
                            chart.options.tooltips.enabled = true;
                        }
                    },
                    beforeDraw: function(chart) {
                        //text 1
                        if (chart.canvas.id != 'doughnut-chart') return;
                        let width = chart.chart.width;
                        let height = chart.chart.height;
                        let ctx = chart.chart.ctx;

                        //clear
                        let canvas = document.getElementById('doughnut-chart');
                        ctx.clearRect(0, 0, canvas.width, canvas.height);

                        ctx.restore();
                        let fontSize1 = (height / 400).toFixed(2);
                        ctx.font = fontSize1 + "em Prompt";
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = 'black';

                        let text1 = val[0];
                        let textX1 = Math.round((width - ctx.measureText(text1).width) / 2);
                        let textY1 = (height-100) / 2;
                        ctx.fillText(text1, textX1, textY1);
                        ctx.save();
                        //text 2
                        // let text2 = Number.isInteger(val[1]) ? Intl.NumberFormat().format(val[1]) : 0;
                        let text2 = Intl.NumberFormat().format(val[1]);
                        let textX2 = Math.round((width - ctx.measureText(text2).width - 50) / 2);
                        let textY2 = height / 2;

                        ctx.restore();
                        let fontSize2 = (height / 200).toFixed(2);
                        ctx.font = "bold " + fontSize2 + "em Prompt";
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = 'rgba(30, 71, 151, 1)';
                        ctx.fillText(text2, textX2, textY2);
                        ctx.save();
                        //text 3
                        //เฉลี่ย 46 ราย/วัน
                        let text3 = 'เฉลี่ย ' + Intl.NumberFormat().format(val[2]) + ' ราย/วัน';
                        // let text3 = 'เฉลี่ย ' + Intl.NumberFormat().format(1000) + ' ราย/วัน';
                        // let textX3 = Math.round(ctx.measureText(text3).width/2+23);
                        let textY3 = (height+100) / 2;

                        ctx.restore();
                        let fontSize3 = (height / 500).toFixed(2);
                        ctx.font = "bold " + fontSize3 + "em Prompt";
                        ctx.textBaseline = "middle";
                        ctx.clearRect(210, textY3, ctx.width, ctx.height);
                        ctx.fillText(text3, 210, textY3);
                        ctx.save();
                    }
                });
            }
        }
    }
</script>
